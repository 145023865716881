<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_search_group">
        <el-form :inline="true" size="min" label-width="120px" :model="searchForm" ref="searchForm" @keyup.enter.native="getCustsNow()">
          <el-row>
            <el-col :md="8">
              <el-form-item label="客户编号:">
                <el-input size="small" v-model.trim="searchForm.cust_no" clearable placeholder="请填写客户编号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="客户简称:">
                <el-input size="small" v-model.trim="searchForm.cust_abbr" clearable placeholder="请填写客户简称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="客户属性:" prop="cust_attr">
                <el-select size="small" filterable v-model="searchForm.cust_attr" placeholder="请选择客户属性">
                  <el-option v-for="item in custAttrList" :key="item.code" :label="item.attr" :value="item.attr"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :md="8">
              <el-form-item label="客户全称:">
                <el-input size="small" v-model.trim="searchForm.cust_name" clearable placeholder="请填写客户全称"></el-input>
              </el-form-item>
            </el-col>

            <el-col :md="8">
              <el-form-item label="所属国家:">
                <el-select v-model="searchForm.cust_country" placeholder="请选择所属国家" size="small" clearable>
                  <el-option v-for="item in custCountryList" :key="item.id" :label="item.param1" :value="item.param1"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="指定外销员" prop="cust_stff_id">
                <el-select v-model="searchForm.cust_stff_id" placeholder="请选择指定外销员" size="small" clearable filterable>
                  <el-option v-for="item in stffUserList" :key="item.stff_id" :label="item.stff_name" :value="item.stff_id"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :md="8">
              <el-form-item label="外销员所属部门" prop="cust_dept_id">
                <el-select v-model="searchForm.cust_dept_id" size="small" placeholder="请选择外销员所属部门" filterable>
                  <el-option v-for="item in deptList" :key="item.dept_id" :value="item.dept_id" :label="item.dept_name"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="录入人:" prop="stff_id">
                <el-select filterable v-model="searchForm.stff_id" placeholder="请选择录入人" size="small" clearable>
                  <el-option v-for="item in stffUserList" :key="item.stff_id" :label="item.stff_name" :value="item.stff_id"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="单据状态:" prop="status">
                <el-select filterable v-model="searchForm.status" placeholder="请选择单据状态" size="small" clearable>
                  <el-option v-for="item in statusList" :key="item.id" :label="item.label" :value="item.id"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :md="14">
              <el-form-item label="录入时间:">
                <div class="block">
                  <el-date-picker
                    size="small"
                    v-model="searchForm.timeValue"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  >
                  </el-date-picker>
                </div>
              </el-form-item>
              <el-form-item>
                <el-button size="small" type="primary" icon="el-icon-search" @click="getCustsNow()" class="vg_ml_16">查询 </el-button>
                <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()"
                  ><i class="el-icon-refresh-right"></i> 刷新</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="vd_button_group vg_mtb_16">
        <el-button type="primary" size="small" @click="addRow()" :disabled="!btn.add"><i class="el-icon-plus"></i> 新增 </el-button>
        <!-- <el-button type="danger" size="small" :disabled='!btn.delete' @click="doDelete()"><i class="el-icon-delete"></i>
          删除</el-button> -->
      </div>
      <el-row>
        <el-col :md="24">
          <el-table
            class="vg_cursor"
            ref="multiTable"
            :data="tableData"
            @selection-change="handleSelectionChange"
            @select="selectRows"
            @select-all="selectRows"
            @row-dblclick="dbClickJp"
            border
            v-loading="loadFlag"
          >
            <el-table-column label="客户编号" prop="cust_no" />
            <el-table-column label="客户简称" prop="cust_abbr" />
            <el-table-column label="客户全称" prop="cust_name" />
            <el-table-column label="客户属性" prop="cust_attr" />
            <el-table-column label="所属国家" prop="cust_country" />
            <el-table-column label="联系方式" :formatter="formatterNmae" />
            <el-table-column label="指定外销员" prop="cust_stff_name" />
            <el-table-column label="外销员所属部门" prop="cust_dept_name" />
            <el-table-column label="录入人" prop="stff_name" show-overflow-tooltip :formatter="helper.personCombina" />
            <el-table-column label="录入时间" prop="create_time" :formatter="formatDate" />
            <el-table-column label="单据状态" :show-overflow-tooltip="true" align="center">
              <template slot-scope="scope">
                <el-tag :type="helper.getStatusName(scope.row.status).type" size="mini">
                  {{ helper.getStatusName(scope.row.status).name }}</el-tag
                >
              </template>
            </el-table-column>
            <!-- <el-table-column label="同步系统" prop="cust_fcp_flag"  align='center'>
           <template slot-scope="scope">
              <div class="vd_sync">
                <el-tag v-if="scope.row.cust_fcp_flag" type="success">FCP</el-tag>
                <el-tag v-else class="vd_font">暂无</el-tag>
              </div>
           </template>
         </el-table-column> -->
          </el-table>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="24">
          <pubPagination :totalPage="totalPage" :pageSize="20" @changePageSearch="changePageSearch" ref="pubPagination"> </pubPagination>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { custAPI } from '@api/modules/cust';
import { optnAPI } from '@api/modules/optn';
import { openAPI } from '@api/modules/open';
import pubPagination from '@/components/common/pubPagination';

export default {
  name: 'CustList',
  components: {
    pubPagination
  },
  data() {
    return {
      tableData: [],
      searchForm: {
        cust_no: null,
        cust_name: null,
        cust_country: null,
        cust_stff_id: null,
        cust_abbr: null,
        cust_attr: null,
        stff_id: null,
        status: null,
        timeValue1: null,
        cust_dept_id: null
      },
      totalPage: 0,
      btn: {},
      loadFlag: true,
      multiSelection: [],
      currentPage: 1,
      custCountryList: [],
      custAttrList: [
        {
          attr: '最终客户',
          code: 'f'
        },
        {
          attr: '中间商',
          code: 'm'
        }
      ],
      stffUserList: [],
      deptList: [],
      statusList: [
        {
          id: 0,
          label: '草拟'
        },
        {
          id: 1,
          label: '在批'
        },
        {
          id: 2,
          label: '生效'
        }
      ]
    };
  },
  created() {
    this.initData();
  },
  watch: {
    $route(to, from) {
      if (from.path === '/cust_add' || from.path === '/cust_edit') {
        this.initData();
      }
    }
  },
  methods: {
    initData() {
      this.getContry();
      this.getCustsList();
      // this.getCustAttrList()
      this.getStffUser();
      this.getDept();
    },
    // 从ERP获取部门信息
    getDept() {
      get(openAPI.getAllDeptsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.deptList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    // 从ERP获取客户信息list
    getCustsList() {
      const timeNewVal = this.helper.getTime(this.searchForm.timeValue);
      get(openAPI.getCusts, {
        cust_no: this.searchForm.cust_no,
        cust_name: this.searchForm.cust_name,
        cust_country: this.searchForm.cust_country,
        cust_abbr: this.searchForm.cust_abbr,
        cust_attr: this.searchForm.cust_attr,
        cust_stff_id: this.searchForm.cust_stff_id,
        page_no: this.currentPage,
        stff_id: this.searchForm.stff_id,
        status: this.searchForm.status,
        start_time: timeNewVal.startTime,
        end_time: timeNewVal.endTime,
        cust_dept_id: this.searchForm.cust_dept_id
      })
        .then(res => {
          if (res.data.code === 0) {
            this.loading = false;
            this.tableData = res.data.data.list;
            this.totalPage = res.data.data.total;
            this.btn = res.data.data.btn;
            setTimeout(() => {
              this.loadFlag = false;
            }, 500);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(() => {});
    },
    // 获取客户属性
    // getCustAttrList() {
    //   get(optnAPI.getOptnByPermId, { perm_id: 10006 })
    //     .then((res) => {
    //       if (res.data.code === 0) {
    //         this.custAttrList = res.data.data.form.optn_cntt_list
    //       }
    //     }).catch(() => { })
    // },
    // 从DYJ获取国家
    getContry() {
      get(optnAPI.getOptnByPermId, { perm_id: 10007 })
        .then(res => {
          if (res.data.code === 0) {
            this.custCountryList = res.data.data.form.optn_cntt_list;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 从ERP获取外销员list
    getStffUser() {
      get(openAPI.getAllStffsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.stffUserList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    // 刷新
    buttonRefresh() {
      this.searchForm = {
        cust_no: null,
        cust_name: null,
        cust_country: null,
        cust_stff_id: null,
        cust_abbr: null,
        cust_attr: null,
        stff_id: null,
        status: null,
        timeValue1: null
      };
      this.loadFlag = true;
      this.currentPage = 1;
      this.initData();
      this.$refs.pubPagination.currentPage = 1;
    },
    // 查询方法
    getCustsNow() {
      this.loadFlag = true;
      this.$refs.pubPagination.currentPage = 1;
      this.currentPage = 1;
      this.getCustsList();
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    // 联系人
    formatterNmae(val) {
      if (val.cust_contact != null && val.cust_contact != '' && val.cust_contact.length > 1) {
        return val.cust_contact + ',' + val.cust_phone;
      }
    },
    // 判断是否可以删除
    selectRows(selection) {
      this.btn.delete = this.helper.isDelete(selection);
    },
    // 双击row跳转到详情编辑
    dbClickJp(row) {
      this.jump('/cust_edit', {
        key: this.$UrlEncode.encode(
          JSON.stringify({
            perm_id: this.$route.query.perm_id,
            form_id: row.cust_id
          })
        )
      });
      // const permId = this.$route.query.perm_id
      // this.jump('/cust_edit',{perm_id:permId,form_id:row.cust_id})
    },
    // //新增
    addRow() {
      const permId = this.$route.query.perm_id;
      const personal = this.$cookies.get('userInfo').personal;
      this.jump(`/cust_add`, {
        key: this.$UrlEncode.encode(JSON.stringify({ perm_id: permId, personal: personal }))
      });
      // const permId = this.$route.query.perm_id
      // this.jump(`/cust_add?perm_id=${permId}`)
    },
    // 分页查询
    changePageSearch(val) {
      this.loadFlag = true;
      this.currentPage = val;
      this.getCustsList();
    },
    // 时间转换
    formatDate(row) {
      return this.helper.toStringDate(row.create_time);
    },
    // 未选中提示
    doDelete() {
      if (this.multiSelection.length === 0) {
        this.$message({ type: 'warning', message: '请至少选择一条数据！' });
      } else {
        let temp = false;
        for (let i = 0; i < this.multiSelection.length; i++) {
          if (this.multiSelection[i].status === 2) {
            temp = true;
            break;
          }
        }
        if (temp) {
          this.$message({ type: 'error', message: '此单据已生效或者正在审批中,无法删除！' });
          this.$refs.multiTable.clearSelection();
        } else {
          this.mBox();
        }
      }
    },
    //客户信息list删除提示
    mBox() {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const ids = [];
          this.multiSelection.map(item => {
            ids.push(item.cust_id);
          });
          post(custAPI.deleteCustByIds, { cust_id_list: ids })
            .then(res => {
              let mg = res.data.msg;
              if (res.data.code === 0) {
                let tp = 'success';
                this.$message({ message: mg, type: tp });
                if (ids.length === this.tableData.length) {
                  if (this.currentPage > 1) {
                    this.currentPage = this.currentPage - 1;
                    this.$refs.pubPagination.currentPage = this.currentPage;
                  }
                }
                this.initData();
              } else {
                let tp = 'error';
                this.$message({ message: mg, type: tp });
                this.initData();
              }
            })
            .catch(res => {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({ message: mg, type: tp });
              this.initData();
            });
        })
        .catch(() => {
          let mg = '取消删除！';
          let tp = 'info';
          this.$message({ message: mg, type: tp });
        });
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}
</style>
